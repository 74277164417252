import '../Page.css';
import './Blog.css';

import React from 'react';
import 'react-slideshow-image/dist/styles.css'
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import ReactTooltip from "react-tooltip";
import { useEffect } from 'react';

import fika from "../../img/Posts/Post7_2.jpg"
import Naturgodis from "../../img/Posts/Post7_1.jpg"

const Title = "6x Zweden missen: Dagelijks leven editie";

export default function Blog7() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <div id='Blog_28-9-22' className="Blog Page">
      <Helmet>
        <title>{Title}</title>
      </Helmet>
      <h1>{Title}</h1>
      <h3 className='Date'>28 september 2022</h3>
      <hr/>
      
      <p>
        Ik ben inmiddels alweer drie maanden terug uit Zweden. Ik ben een nieuwe studie gestart en verhuisd naar Nijmegen. Hoewel het me goed doet om hier weer met iets nieuws te 
        beginnen, kan ik het niet helpen dat ik Zweden nog vaak mis. Het zit hem in de grote dingen als vrienden missen, maar vooral ook in de kleine alledaagse dingen zoals bepaalde 
        dingen niet kunnen vinden in de supermarkt. Dit blog is een opsomming van dingen in het alledaagse leven die ik mis aan Zweden nu ik weer terug in Nederland ben. Dingen die 
        eerst misschien wat vreemd waren, maar waar ik nu gewend aan ben geraakt. 
      </p>
      
      <h3>1. Kapstokken in collegezalen</h3>
      <p>
        Toen ik eerst in Zweden op de universiteit kwam vond ik het maar raar: Kapstokken in de collegezaal. Hangen mensen daar echt hun jas op? Je wilt je jas toch gewoon bij je 
        houden? In Nederland is dit namelijk helemaal niet normaal. Je hangt je je jas over je stoel of je legt hem naast je. Maar deze houding veranderde snel toen ik met mijn 
        dikke (soms natte) winterjas en sjaal de zalen binnen kwam. Het voelde niet meer dan logisch om deze gewoon naast de deur of aan de zijkant op te hangen en niet bezig te 
        zijn met dat jas achter je hangt en de vloer aan het dweilen is. 
      </p>
      <p>
        Weer terug in Nederland, mis ik het fenomeen van een kapstok in de collegezaal. Zeker nu de herfst weer begonnen is, kom je met je natte jas binnen en moet je hem dan maar 
        gewoon achter je hangen of leg je hem op de stoel naast je, waardoor je een extra plek bezet. Vaak glijden mijn ogen uit automatisme langs de muren om te zoeken naar haakjes 
        voor mijn jas, maar tevergeefs. 
      </p>

      <h3 >2. Een alternatief voor koemelk</h3>
      <p>
        Bij mijn favoriete koffietent in Uppsala, UNO, vroegen ze standaard bij mijn cappuccino welke melk ik daarbij wilde. Bij de koffiekan stond áltijd koemelk én havermelk 
        (of een ander alternatief). Een alternatieve melksoort is zo normaal in Zweden dat je hiervoor niet eens meer extra hoeft te betalen. En hoewel het in Nederland steeds 
        normaler wordt en cafés wel vaak al een alternatief hebben (wat een vooruitgang is), betaal je toch vaak nog meer en daar moet ik weer aan wennen.
      </p>

      <div className="imgDiv">
        <img src={fika} alt="vitrine bij een fika café" />
      </div>

      <h3>3. Fika cafés</h3>
      <p>
        Ik heb het in de blogs al vaker gehad over de typische Zweedse traditie: <i>Fika</i>. Een koffiemomentje ergens op de dag, waarin je even je rust pakt of bijpraat met 
        vrienden/collega’s/familie onder het genot van koffie mét gebak. In de Zweedse cafés zie je deze traditie terug. Cafés hebben vitrines vol met lekkernijen. De keuze is reuze. 
        Je kunt hier dan ook wel lunchen, maar die opties zijn vaak nét iets beperkter dan in Nederland.
      </p>
      <p>
        Dit vormt een groot verschil in Nederland, waar cafés vaak gericht zijn op de lunch en diner, met maar een paar opties voor iets lekkers bij de koffie. Als je geluk hebt, kan 
        je naast appelgebak ook nog twee andere opties kiezen. Het is beter voor mijn portemonnee om alleen een kopje koffie te nemen, maar ik verlang best nog eens naar de vitrines 
        vol met taart, broodjes en koeken.
      </p>


      <h3>4. Automatische deuren</h3>
      <p>
        Het was even wennen toen ik net aan kwam, overal zware deuren die makkelijker te openen waren met een knop naast de deur. Deze knop draagt bij voor de toegankelijkheid van 
        gebouwen en is fijn voor gehandicapte personen. Maar als vanzelf werd deze knop voor mij ook de normaal, zeker ’s ochtends is het fijner om rustig de deur voor je te laten 
        openen dan te gaan sjorren aan een deur die eerst moeilijk open gaat. Qua toegankelijkheid kan Nederland nog veel leren van Zweden.
      </p>

      <h3>5. Afval scheiden</h3>
      <p>
        Zweden zijn pros in het scheiden van afval. Op de universiteit staan er altijd meerdere prullenbakken, in mijn studentenhuis hadden wij 6 extra bakken, naast het restafval 
        en gft-afval, om het afval in te scheiden en ook bij de McDonalds is een heel afvalscheidsstation. Ik weet dat in Nederland gemeentes nu zeggen dat zij effectiever afval 
        kunnen scheiden, maar het voelt nu erg slecht als ik bij de McDonalds mijn hele blad zo in één prullenbak kieper. 
      </p>

      <div className="imgDiv">
        <img src={Naturgodis} alt="Nootjes scheppen" />
      </div>

      <h3>6. Naturgodis</h3>
      <p>
        <i>Naturgodis</i>, ik was niet direct fan. Direct vertaald betekent het <i>natuurlijk snoep</i>. Het zijn nootjes die je in de supermarkt ICA uit bakken kunt scheppen in 
        een zakje, net als snoep dus. Je vindt daar pinda’s, cashewnoten en andere noten, in allerlei verschillende soorten smaken en vormen. Zo had je cashewnoten met zeezout, 
        Italiaanse kruiden of knoflooksmaak of bedekt met een laagje witte-of melkchocolade. Mijn huisgenoten namen vaak wat <i>Naturgodis</i> mee, maar ik eerst nog niet. Tot ik 
        op een gegeven moment er zo vaak langs was gelopen en erover had gehoord, dat ik het ook maar probeerde!
      </p>
      <p>
        Vanaf toen was ik fan en nu vind ik het een heel fijn concept en baal ik ervan dat ze dit niet in Nederland hebben. Soms wil ik gewoon iets snacken wat niet per se ongezond 
        is. <i>Naturgodis</i> was dan perfect. Hier kan ik ook wel een zakje cashewnoten kopen, maar dan heb ik maar één smaak en dat is vaak duur. In Zweden kocht je gewoon een 
        kleine hoeveelheid, met verschillende smaken en dat was top. Ik kan niet wachten tot de Jumbo of Albert Heijn ook van dit concept hoort en het overneemt!
      </p>

      <p>
        Dit zijn zomaar wat kleine alledaagse dingen die ik mis aan het leven in Zweden. Ik zou nog wel even door kunnen gaan met dingen die ik mis aan het land of het studentenleven, 
        maar dat is materiaal voor een volgend blog! Deze lijst is trouwens niet bedoelt om te pleiten dat het dagelijks leven in Zweden perfect is of altijd makkelijk was. Ik weet 
        zeker dat ik eenzelfde lijst kan maken over dingen die ik miste aan Nederland toen ik in Zweden was. Wie weet ook een idee voor een volgend blog! 
      </p>
           
      <p>Hej då, <br />Babette </p>
      <div className="prevNextButs">
        <Link style={{visibility: "hidden"}} id='nextBlog' className='Link' to="/blogs/blog_20-3-22"> Volgende Blog </Link>
        <Link id='prevBlog' className='Link' to="/blogs/blog_31-5-22"> Vorige Blog: <br /><span>Ik leef nog!</span></Link>  
      </div>

      <ReactTooltip offset={{top: -10}} effect='solid'/>
    </div>
  );
}