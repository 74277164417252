import React from 'react'
import { Link } from 'react-router-dom'

export default function BlogIntro(props) {
  return (
    <div>
      <div className='intro'>
        <h3>{props.title}</h3>
        <h4>{props.date}</h4>
        <p>{props.txt} <Link className='Link' to={props.link}>Lees meer</Link></p>
      </div>
      <hr />
    </div>
  )
}
